.logincontainer h2 {
    color: #6C584C; /* Dark brown for all headings */
}

.sign-up h2,
.sign-in h2 {
    color: #6C584C; /* Dark brown for these headings */
}

.toggle-panel h2 {
    color: #6C584C; /* Dark brown for all h2 headings inside toggle-panel */
}

.toggle-left h2,
.toggle-right h2 {
    color: #6C584C; /* Dark brown for these h2 headings */
}

.logincontainer input::placeholder {
    color: #6C584C; /* Dark brown for placeholder text */
}

.logincontainer input:focus {
    color: #6C584C; /* Dark brown for input text when focused */
}

.logincontainer {
    background-color: #F0EAD2; /* Maintain light cream color for the container */
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

.logincontainer p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.logincontainer span {
    font-size: 12px;
}

.logincontainer a {
    color: #6C584C; /* Dark brown for links */
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
}

.logincontainer button {
    background-color: #6C584C; /* Dark brown for button */
    color: #F0EAD2; /* Light cream for button text */
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.logincontainer button.hidden {
    background-color: transparent;
    border-color: #6C584C; /* Dark brown for border */
}

.logincontainer form {
    background-color: #F0EAD2; /* Light cream for the form background */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.logincontainer input {
    background-color: #F0EAD2; /* Light cream for input background */
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
    color: #6C584C; /* Dark brown text color in inputs */
}

.form-logincontainer {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in {
    left: 0;
    width: 50%;
    z-index: 2;
}

.logincontainer.active .sign-in {
    transform: translateX(100%);
}

.sign-up {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.logincontainer.active .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }
    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

.social-icons {
    margin: 20px 0;
}

.social-icons a {
    border: 1px solid #ccc; /* Maintain default border color */
    border-radius: 20%; /* Standard roundness for icons */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
}

.toggle-logincontainer {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 1000;
}

.logincontainer.active .toggle-logincontainer {
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.toggle {
    background-color: #6C584C; /* Dark brown */
    height: 100%;
    background: linear-gradient(to right, #6C584C, #A98467); /* Dark brown to tan */
    color: #F0EAD2; /* Light cream for toggle text */
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.logincontainer.active .toggle {
    transform: translateX(50%);
}

.toggle-panel {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggle-left {
    transform: translateX(-200%);
}

.logincontainer.active .toggle-left {
    transform: translateX(0);
}

.toggle-right {
    right: 0;
    transform: translateX(0);
}

.logincontainer.active .toggle-right {
    transform: translateX(200%);
}